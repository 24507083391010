import ContainerMain from "src/pages/common/ContainerMain"
import image2 from "images/Channels/SocialCommerce/channel-2-4.png"

import "./ModelConcept2.scss"
import { useFormatter } from "helpers/i18n"

export default function ModelConcept2() {
  const { __ } = useFormatter()

  return (
    <>
      <div className="gqaqclvmrn">
        <ContainerMain>
          <div className="nqvvuwfvto">
            <div className="qqfjjtjdyh">
              <div className="exhkfyjisv">
                <div className="obzceupcdg">
                  {__(
                    {
                      defaultMessage: "Promotion & {br}Campaign Management",
                    },
                    { span: (...chunks) => <span>{chunks}</span>, br: <br /> }
                  )}
                </div>
              </div>
              <div className="pqvoastcix">
                <div className="fycfjfnyns">
                  <img
                    className="qudwdlsajh"
                    src={image2}
                    alt="Promotion & Campaign Management"
                  />
                </div>
              </div>
            </div>
            <div className="kfuwqrqixj">
              <div className="ectncpfykc">
                <div className="kbzhwtnwpt">
                  {__({
                    defaultMessage:
                      "Support category growth drivers and address purchase barriers",
                  })}
                </div>
              </div>
              <div className="ectncpfykc">
                <div className="kbzhwtnwpt">
                  {__({
                    defaultMessage:
                      "Help brands to grow sale and add more value",
                  })}
                </div>
              </div>
              <div className="ectncpfykc">
                <div className="kbzhwtnwpt">
                  {__({
                    defaultMessage:
                      "Develop short video and enhance content strategy",
                  })}
                </div>
              </div>
            </div>
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
